import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const Testamento: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <GatsbySeo
        title="TESTAMENTO"
        description="L'idea e' quella di vivere nella maniera piu' green possibile nella speranza di una qualche evoluzione e/o creazione di un prodotto unico"
        canonical="https://www.casafraine.com/testamento"
        openGraph={{
          url: "https://www.casafraine.com/testamento",
          title: "Life",
          description:
            "L'idea e' quella di vivere nella maniera piu' green possibile nella speranza di una qualche evoluzione e/o creazione di un prodotto unico",

          images: [
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 800,
              height: 600,
              alt: "life",
            },
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 900,
              height: 800,
              alt: "life",
            },
            { url: "https://www.casafraine.com/mapitaly.png" },
            { url: "https://www.casafraine.com/mapitaly.png" },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "Vincenzo Marcovecchio",
          site: "Casa Fraine",
          cardType: "summary_large_image",
        }}
      />
      <section className="booking">
        <h1>
          Nota: questo e' il mio vero testamento in caso dovessi morire, mi
          ammazzano o quant'altro
        </h1>

      </section>
    </React.Fragment>
  );
};

export default Testamento;
